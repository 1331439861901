import React from 'react'
import { Link } from 'gatsby'

const Succes = () => (
  <>
    <p>J'ai bien reçu votre message.<br/>Je vous contacterai d'ici peu.</p>
    <Link to="/">Retour à la page d'accueil</Link>
  </>
)

export default Succes

